import React from "react";
import styled from 'styled-components';

export default class Form extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    }; 
  }

  render(props) {
    const { status } = this.state;
    return (
      <StyledForm
        onSubmit={this.submitForm}
        action={"https://formspree.io/" + this.props.formid}
        method="POST"
      >
        <div className="col">
            <label>Your name <span>*</span></label>
            <input type="text" name="name" required />
          </div>
          <div className="col">
            <label>Your email <span>*</span></label>
            <input type="email" name="email" required />
          </div>
          <div className="col">
            <label>Place you recommend <span>*</span></label>
            <input type="text" name="recommendation" required />
          </div>
           <div className="col">
            <label>Website</label>
            <input type="text" name="website" />
          </div>
           <div className="col-100">
            <label>Other information</label>
            <textarea name="info" />
          </div>

          <div className="col-100">  
            <input type="text" name="_gotcha" style={{display: `none`}} />
        
            {status === "SUCCESS" ? <p><br/><strong>Thanks for your recommendation submission, we will review your submission!</strong></p> : <button>Submit</button>}
            {status === "ERROR" && <p>Ooops! There was an error.</p>}
          </div> 
      </StyledForm>
 
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}

const StyledForm = styled.form`
  flex-direction: row;
    display: flex;
    flex-wrap: wrap;

    input,
    textarea {
      font-size: 16px;
      padding: 5px;
      border: 1px solid #ff9900;
    }

    label {
      font-weight: bold;
      color: #1c2429;

      span {
        color: red;
      }
    }

    button {
      width: 150px;
      background-color: #ff9900;
      color: #fff;
      outline: none;
      border: none;
      font-size: 16px;
      padding: 12px 10px;
      border-radius: 5px;
      border: 1px solid #ff9900;

      &:hover {
        cursor: pointer;
        background-color: transparent;
        color: #ff9900;
        border: 1px solid #ff9900;
      } 
    }

    .col,
    .col-100 {
      margin-bottom: 20px;
    }

  .col {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
      padding: 0 15px;

      @media(min-width: 600px) {
        flex-basis: 50%;
      }
  }

  .col-100 {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    padding: 0 15px;
  }
`