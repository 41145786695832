import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import Form from 'components/Form';

class Recommend extends Component {

	  render(props) {
	  	const bgImage = this.props.data.file.childImageSharp.fluid.src;

	  	return(

	  		<Layout pageName="Recommend">
		      <Helmet>
		        <title>Recommend the best places in Brighton</title>
		      </Helmet>
		      <div
		        className="page-hero"
		        style={{
		          backgroundImage: `url(${bgImage})`,
		        }}
		      >
		        <h1>Recommend the best places in Brighton</h1>
		      </div>

		      <div className="container container-content">
			    <Intro>	
			    	<p>Are you local to Brighton? Do you have a recommendation of a place of interest, eatery, shop, coffee shop or experience that isn't currently on the site?</p>
			    </Intro>

		      	<Form formid="xknknwak"/>
		      </div>
	  		</Layout>
	  	);
	  }
}


export default Recommend

const Intro = styled.div`
	padding: 0 20px 20px 20px;

`

export const query = graphql`
  query {
    file(relativePath: { eq: "banner-images/recommend.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, fit: COVER) {
        	src
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`